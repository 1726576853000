import React from 'react'
import '../shared-styling/global.css'
import Navigation from './navigation'
import styled, { ThemeProvider } from 'styled-components'
import { fluidRange } from 'polished'

/* Global Styling Themes */
const theme = {
  gray: '#b2b2b2',
  pageText: fluidRange(
    { prop: 'font-size', fromSize: '18px', toSize: '24px' },
    '375px',
    '1200px'
  ),
  blogTitle: fluidRange(
    { prop: 'font-size', fromSize: '27px', toSize: '42px' },
    '375px',
    '1200px'
  ),
  headerText: fluidRange(
    { prop: 'font-size', fromSize: '42px', toSize: '72px' },
    '375px',
    '1200px'
  ),
  urduFont: {
    'line-height': 2.5,
    'font-family': 'Noto Nastaliq Urdu, serif',
  },
}

/* Component */
const Layout = ({ children }) => {
  return (
    <Wrapper>
      <Template>
        <ThemeProvider theme={theme}>
          <Navigation />
          <Main>{children}</Main>
        </ThemeProvider>
      </Template>
    </Wrapper>
  )
}

export default Layout

/* Component Styling */
const Template = styled.div`
  margin: 0px 4%;
  line-height: 1.65;
`
const Wrapper = styled.div`
  margin: auto;
  max-width: 1200px;
  width: calc(100% - 5vmin);
`
const Main = styled.main`
  padding: 2vmin 0;
`
