import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { fluidRange } from 'polished'

/* Component */
const NavBar = () => (
  <NavBarWrapper>
    <NavItem>
      <StyledLink activeClassName="active-nav" to="/">
        MAGAZINE
      </StyledLink>
    </NavItem>
    <NavItem>
      <StyledLink activeClassName="active-nav" to="/about">
        ABOUT
      </StyledLink>
    </NavItem>
    <ContactNavItem>
      <StyledLink activeClassName="active-nav" to="/contact">
        CONTACT
      </StyledLink>
    </ContactNavItem>
  </NavBarWrapper>
)

export default NavBar

/* Styling */
const textSizing = {
  min: `14px`,
  max: `18px`,
}
const StyledLink = styled(Link)`
  text-decoration: none;
  &.active-nav {
    color: black;
  }
`
const NavBarWrapper = styled.nav`
  ${fluidRange(
    {
      prop: 'font-size',
      fromSize: `${textSizing.min}`,
      toSize: `${textSizing.max}`,
    },
    '375px',
    '1200px'
  )};
  display: flex;
  list-style: none;
  height: 20vh;
  max-height: 100px;
`
const NavItem = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: 27px;
  color: ${(props) => props.theme.gray};
  :hover {
    text-decoration: underline;
  }
`
const ContactNavItem = styled(NavItem)`
  margin-left: auto;
  margin-right: 10px;
`
